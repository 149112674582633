import React, { Component } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';

// Our Components
import Homepage from './../Homepage/homepage'; 
import Disclaimer from '../Disclaimer/disclaimer';
import Privacy from '../Disclaimer/privacy';
import Notice from '../Disclaimer/notice'; 

class NavRoutes extends Component {
  render() {
    return (
      <main style={{height: "100%", width: "100%"}}> 
        <Router history={this.props.history}>
          
        <Switch>
            <Route exact path='/' component={Homepage}/>
            <Route path="/home" component={Homepage}/>
            <Route path="/disclaimer" component={Disclaimer}/>
            <Route path="/privacy" component={Privacy}/>
            <Route path="/notice" component={Notice}/>
            <Route component={Homepage} />
          </Switch>
          </Router> 
        </main>
    );
  }
}
export default NavRoutes;
