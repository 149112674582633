import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { Link } from 'react-scroll';

import HKMap from '../../images/HKMAP.jpg';
import SGMap from '../../images/SGMAP.jpg';

const HK = require('../../images/Hong Kong.jpg');
const Patrik = require('../../images/Patrik.jpg');
const Chris = require('../../images/Chris.jpg');
const Jim = require('../../images/Jim.jpg');


class Homepage extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return this.showPage();
    }

    showPage() {
        return (
            <div>
                <section id="home">
                    <div className='container-fluid' style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                        <div className='col-md-12' style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                            <div style={{ width: "100%", maxHeight: "100vh", overflow: "hidden", top: 0, right: 0, zIndex: -100, marginTop: "-1px" }}>
                                <Carousel
                                    autoPlay={true}
                                    infiniteLoop={false}
                                    showArrows={false}
                                    dynamicHeight={true}
                                    transitionTime={2500}
                                    interval={4000}
                                    showStatus={false}
                                    showThumbs={false}
                                    showIndicators={false}
                                >
                                    <div>
                                        <img src={HK} />
                                        {/* <div style={{ position: "absolute", top: "40%", left: "50%", transform: "translate(-50%, -50%)", color: "white", textAlign: "center" }}>
                                            <h1 style = {{fontSize: "60px"}}>Your Partner for Asia.</h1>
                                        </div> */}
                                    </div>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </section>
                <section id='about'>
                    <div className="">
                        <section>
                            <div className='container' style={{ paddingTop: "8%", paddingBottom: "8%" }}>
                                <div className='row'>
                                    <div className='col-xl-4 col-lg-4 col-md-12 col-12 border-right-blue ptb70 height-adjust' style= {{textAlign: "center"}}>
                                        <h1 className="heading">
                                            Welcome to <br/> Tor Investment Management
                                        </h1>
                                    </div>
                                    <div className='col-xl-7 col-lg-7 col-md-12 col-12' style={{ paddingLeft: "5%" }}>
                                        <p className='descPara'>
                                            Tor Investment Management is a leading independent alternative credit manager in Asia with over $2.1 billion under management across one
                                            evergreen fund, four closed-end funds and co-investments.  The firm was founded in 2012 by Patrik Edsparr, former Global Head of JP Morgan’s
                                            Proprietary and Principal Investing Division, and Chris Mikosh, former Head Trader of Goldman Sachs’ Asian Special Situations Group (ASSG),
                                            to provide investors with access to the Asia-Pacific credit markets in opportunities ranging from high yield bonds to traded loans and private
                                            financings.

                                            <br /><br />

                                            Based in Hong Kong and Singapore, the platform consists of 44 employees, including an investment team of 18 professionals.  Tor Investment
                                            Management's global institutional investor base includes public and corporate pension plans, sovereign wealth funds, endowments, foundations,
                                            outsourced CIOs and family offices.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className='container ptb45'>
                                <div className='row'>
                                    <div class='col-md-3 col-6 textEd'>
                                        <p className='fs50p'>$2.1B</p>
                                        <p className='pb1 mb0 fs15'>Assets Under Management</p>
                                    </div>
                                    <div class='col-md-3 col-6 textEd'>
                                        <p className='fs50p'>$8.0B</p>
                                        <p className='pb1 mb0 fs15'>Invested in Asian Credit</p>
                                    </div>
                                    <div class='col-md-3 col-6 textEd'>
                                        <p className='fs50p'>250+</p>
                                        <p className='pb1 mb0 fs15'>Credit Investments with 180+ Exits</p>
                                    </div>
                                    <div class='col-md-3 col-6 textEd'>
                                        <p className='fs50p'>44</p>
                                        <p className='pb1 mb0 fs15'>Staff with 18 Investment Professionals</p>
                                    </div>
                                </div>
                                {/* <div className='row'>
                                    <p className='cBlue' style={{marginTop: "20px", marginLeft: "5%"}}>
                                        **Note: The aforementioned stats are as of December 31, 2022
                                    </p>
                                </div> */}
                            </div>
                        </section>
                        <section>
                            <div className='container' style={{ paddingTop: "8%", paddingBottom: "8%" }}>
                                <div className='row'>
                                    <div className='col-12 col-md-4 border-right-blue ptb70 height-adjust'>
                                        <h2 className="heading">
                                            About Us
                                        </h2>
                                    </div>
                                    <div className='col-xl-7 col-lg-7 col-md-12 col-12' style={{ paddingLeft: "5%" }}>
                                        <div className='row p0'>
                                            <ul>
                                                <li>
                                                    <div className='col-xl-12'>
                                                        <div className='pb25'>
                                                            <p className='fs20 p0 m0 cBlue'>
                                                                Tor Investment Management is an alternative credit manager focusing on the Asia-Pacific markets.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='col-xl-12'>
                                                        <div className='pb25'>
                                                            <p className='fs20 p0 m0 cBlue'>
                                                                Our founders and many senior team members have invested through multiple cycles since the 1997 Asian Financial Crisis.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='col-xl-12'>
                                                        <div className='pb25'>
                                                            <p className='fs20 p0 m0 cBlue'>
                                                                Our 18 experienced investment professionals have previously worked at top-tier firms such as Goldman Sachs, JP Morgan,
                                                                Oaktree, Citadel, GE Capital, Morgan Stanley, UBS, and Citigroup.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='col-xl-12'>
                                                        <div className='pb25'>
                                                            <p className='fs20 p0 m0 cBlue'>
                                                                We focus on delivering alpha with diversification benefits for our investors, with truly pan-Asian, cross-industry credit strategies.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='col-xl-12'>
                                                        <div className='pb25'>
                                                            <p className='fs20 p0 m0 cBlue'>
                                                                We emphasize a culture of inclusion that embraces all forms of diversity, strengthens our productivity and enhances the investment process.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='col-xl-12'>
                                                        <div className='pb25'>
                                                            <p className='fs20 p0 m0 cBlue'>
                                                                We are committed to integrating ESG factors into our investment and risk management process through a proprietary SASB-aligned framework.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='col-xl-12'>
                                                        <div className='pb25'>
                                                            <p className='fs20 p0 m0 cBlue'>
                                                                Tor became a signatory of United Nations Principles for Responsible Investment in March 2021.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                <section id='approach'>
                    <div className='grey-gradient'>
                        <div className='container' style={{ paddingTop: "8%", paddingBottom: "8%" }}>
                            <div className='row'>
                                <div className='col-xl-4 col-lg-4 col-md-12 col-12 border-right-white ptb70 height-adjust'>
                                    <h2 className="heading textWhite">
                                        Our Approach
                                    </h2>
                                </div>
                                <div className='col-xl-7 col-lg-7 col-md-12 col-12' style={{ paddingLeft: "5%" }}>
                                    <p className='descPara textWhite'>
                                        <ul>
                                            <li>
                                                <div className='col-xl-12'>
                                                    <div className='pb25'>
                                                        <p className='fs20 p0 m0 cWhite'>
                                                            Since 2013, Tor has invested over $8 billion using a robust portfolio construction and investment process that optimizes the
                                                            risk/reward across a broad range of economic scenarios.
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='col-xl-12'>
                                                    <div className='pb25'>
                                                        <p className='fs20 p0 m0 cWhite'>
                                                            We seek attractive credit opportunities across the Asia-Pacific corporate and real estate markets and use our local presence,
                                                            understanding of the markets and structuring expertise to realize value.
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='col-xl-12'>
                                                    <div className='pb25'>
                                                        <p className='fs20 p0 m0 cWhite'>
                                                            Our proprietary deal flow is created through leveraging our longstanding relationships and extensive networks.
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='col-xl-12'>
                                                    <div className='pb25'>
                                                        <p className='fs20 p0 m0 cWhite'>
                                                            We focus on secured first-lien structures with short tenors (1-3 years) and avoid equity-like investments structured in debt form.
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='col-xl-12'>
                                                    <div className='pb25'>
                                                        <p className='fs20 p0 m0 cWhite'>
                                                            Our in-depth and bottom-up underwriting process with private equity-style due diligence practices ensures maximum downside protection.
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <section id='leadership'>
                    <div className='blue-gradient'>
                        <div className='container'>
                            <div className='row mb50'>
                                <div className='col-xl-4 col-lg-4 col-md-12 col-12 border-right-white ptb70 height-adjust'>
                                    <h2 className="heading textWhite">
                                        Firm Leadership
                                    </h2>
                                </div>
                                <div className='col-xl-7 col-lg-7 col-md-12 col-12 ptb45' style={{ paddingLeft: "5%" }}>
                                    <h3 className='cWhite fs28' style={{ cursor: "pointer", marginBottom: "40px" }}>
                                        <Link smooth to='pedsparr'>Patrik Edsparr</Link>
                                    </h3>
                                    <h3 className='cWhite fs28' style={{ cursor: "pointer", marginBottom: "40px" }}>
                                        <Link smooth to='cmikosh'>Christopher Mikosh</Link>
                                    </h3>
                                    <h3 className='cWhite fs28' style={{ cursor: "pointer" }}>
                                        <Link smooth to='jsweeney'>James Sweeney</Link>
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className='container' style={{ paddingTop: "5%", paddingBottom: "5%" }}>
                            <section id="pedsparr">
                                <div className='row mb50'>
                                    <div className='col-xl-4 col-lg-4 col-md-12 col-12 border-right-white ptb70 height-adjust'>
                                        
                                        <img src={Patrik} alt="Patrik Edsparr Headshot" class="headshot-image"/>
                                    </div>
                                    <div className='col-xl-7 col-lg-7 col-md-12 col-12 ptb70' style={{ paddingLeft: "5%" }}>
                                        <h2 className='cWhite fs28'>
                                            Patrik Edsparr <br />
                                        </h2>
                                        <h3 className='cWhite fs17'>Chief Investment Officer, Co-Founder</h3>
                                        <p className='descPara cWhite'>
                                            Patrik Edsparr has over 30 years of direct finance and investment management experience. As Chief Investment Officer, Patrik is responsible for
                                            portfolio construction, risk management and chairs the Investment Committee.

                                            <br /><br />

                                            Prior to TOR, he spent most of his professional career at JP Morgan Chase & Co., building and managing the Proprietary Positioning and Principal
                                            Investing Division.  At JP Morgan, his responsibilities included the distressed, mezzanine and special-situations investments across North America,
                                            Europe, and Asia. Patrik was a long-serving member of the Executive Committee and the Investment Bank’s Management Committee.  In addition, he
                                            served on the Capital and Risk Committees.  Previous roles at JP Morgan included Co-head of North American Markets (2004–2006) and Global Co-head
                                            of Fixed Income (2006–2008).  In 2008 Patrik joined Citadel Investment Group as the Global Head of Fixed Income and CEO of Citadel Europe.
                                            From 2010-2011, he spent a year as Visiting Fellow at the Bank of England providing advice on a variety of market-structure and capital issues.

                                            <br /><br />

                                            Patrik holds a PhD in Financial Economics from MIT, where he was a Fulbright Scholar.  He received an MSc in Applied Mathematics from the
                                            Royal Institute of Technology, an MBA from the Stockholm School of Economics, and a BA from Uppsala University.  He was a lieutenant in the
                                            Swedish Army, where he served at the Swedish Defence Language School.  Patrik has also served as a Non-Executive Director of MTS S.p.A. and
                                            Nomura International (and chaired its Board Risk Committee).
                                        </p>
                                    </div>
                                </div>
                            </section>
                            <section id="cmikosh">
                                <div className='row mb50'>
                                    <div className='col-xl-4 col-lg-4 col-md-12 col-12 border-right-white ptb70 height-adjust'>
                                        <img src={Chris} alt="Patrik Edsparr Headshot" class="headshot-image"/>
                                    </div>
                                    <div className='col-xl-7 col-lg-7 col-md-12 col-12 ptb70' style={{ paddingLeft: "5%" }}>
                                        <h2 className='cWhite fs28'>
                                            Christopher Mikosh <br />
                                        </h2>
                                        <h3 className='cWhite fs17'>Portfolio Manager, Co-Founder</h3>
                                        <p className='descPara cWhite'>
                                            Christopher Mikosh has over 30 years of direct credit investing and trading experience. As Portfolio Manager, Chris is responsible for
                                            managing the investment team, overseeing the deal sourcing activities of the firm, and driving the day-to-day investment activities.

                                            <br /><br />

                                            Prior to TOR, he was Managing Director and Head Trader of the Asian Special Situations Group (ASSG) at Goldman Sachs (2000–2012).  During his
                                            tenure, Chris was responsible for managing, sourcing, trading, and hedging a multi-asset class portfolio that averaged $3 billion.  While at
                                            Goldman, Chris sat on the Asia Risk Committee and the Board of Goldman Sachs (Asia) Finance, the firm’s main investing entity in the region.
                                            Chris also sat on the Liquid Investing Committee for Non-Japan Asia, the Liquid Investing Committee for Japan, and the Illiquid Investing
                                            Committee in the US. Prior to Goldman, Chris spent four years at Lazard Frères as a desk analyst and then as Head Trader of the distressed and
                                            high-yield debt desk. Prior to that, he spent two years as a banker in Chase Securities’ high-yield origination group.

                                            <br /><br />

                                            Chris earned a dual degree BS in Finance and Computer Science/Management Information Systems from Boston College in 1994.  He is a member of
                                            the board of the Christina Noble Children’s Foundation.
                                        </p>
                                    </div>
                                </div>
                            </section>
                            <section id="jsweeney">
                                <div className='row mb50'>
                                    <div className='col-xl-4 col-lg-4 col-md-12 col-12 border-right-white ptb70 height-adjust'>
                                        <img src={Jim} alt="Patrik Edsparr Headshot" class="headshot-image"/>
                                    </div>
                                    <div className='col-xl-7 col-lg-7 col-md-12 col-12 ptb70' style={{ paddingLeft: "5%" }}>
                                        <h2 className='cWhite fs28'>
                                            James Sweeney <br />
                                        </h2>
                                        <h3 className='cWhite fs17'>Chief Operating Officer</h3>
                                        <p className='descPara cWhite'>
                                            James Sweeney has over 30 years of experience in finance and investment management. As Chief Operating Officer, Jim oversees all non-investment
                                            activities of the firm including legal, compliance, risk, finance, operations and investor relations.

                                            <br /><br />

                                            Prior to joining TOR, Jim was the CEO of Boyer Allan Investment Management, a Hong Kong-based investment management firm and Chief
                                            Operating Officer at Artradis Fund Management in Singapore (2009–2011).  Before Artradis, he was a Managing Director at Credit Suisse,
                                            where he ran the Fund Linked Products Group in New York (2006-2009).  Jim began his career as a lawyer at Simpson Thacher & Bartlett in New York.

                                            <br /><br />

                                            Jim holds an LLM in taxation from New York University and a JD and BA in Philosophy from Fordham University, New York.
                                        </p>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
                <section id='contact'>
                    <div className="contactBG">
                        <section>
                            <div className='container' style={{ paddingTop: "5%", paddingBottom: "5%" }}>
                                <div className='row'>
                                    <div className='col-xl-4 col-lg-4 col-md-12 col-12 border-right-blue ptb70 height-adjust'>
                                        <h2 className="heading">
                                            Contact Us
                                        </h2>
                                    </div>
                                    <div className='col-xl-7 col-lg-7 col-md-12 col-12' style={{ paddingLeft: "5%" }}>
                                        <div className='container'>
                                            <div className='row'>
                                                <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
                                                    <h3 class = "fs17">Hong Kong</h3>
                                                    <p className='descParaContact'>
                                                        Henley Building, 19/F
                                                        <br></br>
                                                        5 Queen's Road Central
                                                        <br></br>
                                                        Central, Hong Kong
                                                        <br></br>
                                                        T: +852 3698 9000
                                                    </p>
                                                </div>
                                                <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
                                                    <h3 class = "fs17">Singapore</h3>
                                                    <p className='descParaContact'>
                                                        6 Battery Road, #37-01
                                                        <br></br>
                                                        Singapore, 049909
                                                        <br></br>
                                                        T: +65 6597 6800
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <p className='descParaContact' style={{ paddingLeft: "10px" }}>
                                                General Inquiries: info@torinvestment.com
                                                <br></br>
                                                Investor Inquiries: ir@torinvestment.com
                                            </p>

                                            <p className='descParaContact' style={{ paddingLeft: "10px" }}>
                                                <a style={{ cursor: "pointer"}} href="https://hk.linkedin.com/company/tor-investment-management-hk" target="blank">
                                                    <FontAwesomeIcon style={{ fontSize: "30px" }} icon={faLinkedin} />
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className='container-fluid mb30'>
                                <div className='row'>
                                    <div className='col-xl-6 col-lg-6 col-12 content mb30'>
                                        <img src={HKMap} className="img-fluid imgF" />
                                        <a href='https://goo.gl/maps/8BWcqSJ2iHFo7oSi7' target="blank">Get Directions</a>
                                    </div>
                                    <div className='col-xl-6 col-lg-6 col-12 content mb30'>
                                        <img src={SGMap} className="img-fluid imgF" />
                                        <a href='https://goo.gl/maps/FpL7jatb6bKRPg9W6' target="blank">Get Directions</a>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
            </div >
        );
    }
}

export default Homepage;
